import axios from "axios";
import {
  GET_ALL_CATEGORIES,
  ADD_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  DELETE_ALL_CATEGORIES,
  GET_CATEGORY_BY_ID,
} from "./types";
import { setErrors } from "./errorActions";

// ➤ 📌 A#1 : Récupérer toutes les catégories
export const getAllCategories = () => (dispatch) => {
  axios
    .get(`/api/data/categories`)
    .then((res) => {
      dispatch({
        type: GET_ALL_CATEGORIES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(setErrors(err));
    });
};

// ➤ 📌 A#2 : Ajouter une nouvelle catégorie
export const postNewCategories = (categoryData) => (dispatch) => {
  return axios
    .post(`/api/data/categories/add`, categoryData)
    .then((res) => {
      dispatch({
        type: ADD_CATEGORY,
        payload: res.data.category, // On envoie uniquement la nouvelle catégorie
      });
      return res.data.category;
    })
    .catch((err) => {
      dispatch(setErrors(err));
      throw err;
    });
};

// ➤ 📌 A#3 : Mettre à jour une catégorie
export const updateCategory = (id, categoryData) => (dispatch) => {
  return axios
    .put(`/api/data/categories/update/${id}`, categoryData)
    .then((res) => {
      dispatch({
        type: UPDATE_CATEGORY,
        payload: res.data.updatedCategory,
      });
      return res.data.updatedCategory;
    })
    .catch((err) => {
      dispatch(setErrors(err));
      throw err;
    });
};

// ➤ 📌 A#4 : Supprimer une catégorie par ID
export const deleteCategory = (id) => (dispatch) => {
  return axios
    .delete(`/api/data/categories/delete/${id}`)
    .then(() => {
      dispatch({
        type: DELETE_CATEGORY,
        payload: id, // Supprime de Redux
      });
    })
    .catch((err) => {
      dispatch(setErrors(err));
      throw err;
    });
};

// ➤ 📌 A#5 : Supprimer toutes les catégories
export const deleteAllCategories = () => (dispatch) => {
  return axios
    .delete(`/api/data/categories/delete-all`)
    .then(() => {
      dispatch({
        type: DELETE_ALL_CATEGORIES,
      });
    })
    .catch((err) => {
      dispatch(setErrors(err));
      throw err;
    });
};

// ➤ 📌 A#6 : Récupérer une catégorie par ID
export const getCategoryById = (id) => (dispatch) => {
  axios
    .get(`/api/data/categories/${id}`)
    .then((res) => {
      dispatch({
        type: GET_CATEGORY_BY_ID,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(setErrors(err));
    });
};
