import { GET_ALL_SUBSCRIBE, TOGGLE_SUBSCRIBE } from "../actions/types";

const initialState = {
  all_subscribe: {},
  subscribe_loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SUBSCRIBE:
      return {
        ...state,
        all_subscribe: action.payload,
      };
    case TOGGLE_SUBSCRIBE:
      return {
        ...state,
        subscribe_loading: !state.subscribe_loading,
      };
    default:
      return state;
  }
}
