import React, { useState, useEffect } from "react";
import isEmpty from "is-empty";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import MultipleValueTextInput from "react-multivalue-text-input";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const UpdateArticle = ({
  onChange,
  onChangeTags,
  onSubmit,
  editorState,
  onEditorStateChange,
  previewPicture,
  all_categories,
  article_details,
}) => {
  const details = !isEmpty(article_details) ? article_details[0] : {};
  const [selectedDate, setSelectedDate] = useState(
    details.published_date ? moment(details.published_date, "YYYY-MM-DD").toDate() : new Date()
  );
  const [title, setTitle] = useState(details.title || "");
  const [category, setCategory] = useState(details.category || "");
  const [tags, setTags] = useState(details.tags || []);

  useEffect(() => {
    setSelectedDate(
      details.published_date ? moment(details.published_date, "YYYY-MM-DD").toDate() : new Date()
    );
    setTitle(details.title || "");
    setCategory(details.category || "");
    setTags(details.tags || []);
  }, [article_details]);

  return (
    <div className="content-area single-page-area">
      <div className="single-page-content">
        <div className="page-title">
          <h2>Update Article</h2>
        </div>

        <article className="post">
          {/* IMAGE PRINCIPALE */}
          <div className="thumb ta-stroke" style={{ marginBottom: "20px", textAlign: "center" }}>
            <img
              src={!isEmpty(previewPicture) ? previewPicture : details.picture?.url}
              alt="Aperçu"
              style={{ maxWidth: "100%", height: "auto", borderRadius: "8px" }}
            />
          </div>

          <div className="form-group" style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "20px" }}>
            <input name="picture" type="file" accept=".jpg,.jpeg,.png" onChange={onChange} />
          </div>

          {/* FORMULAIRE */}
          <div className="entry-content">
            <div className="row">
              <div className="col-md-12 mt-30">
                <div className="ta-form-group">
                  <input name="title" type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} className="form-control" />
                  <button className="btn btn-primary mt-2" onClick={() => onSubmit("title", { target: { value: title } })}>Mettre à jour</button>
                </div>
              </div>
            </div>

            <div className="row mt-30" style={{ display: "flex", gap: "20px" }}>
              <div className="col-md-6">
                <div className="ta-form-group">
                  <select
                    name="category"
                    className="form-select"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <option value="" disabled>Sélectionner une catégorie</option>
                    {all_categories.map((a) => (
                      <option key={a.name} value={a.name}>{a.name}</option>
                    ))}
                  </select>
                  <button className="btn btn-primary mt-2" onClick={() => onSubmit("category", { target: { value: category } })}>Mettre à jour</button>
                </div>
              </div>

              <div className="col-md-6">
                <div className="ta-form-group">
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => {
                      setSelectedDate(date);
                      onSubmit("published_date", { target: { value: moment(date).format("YYYY-MM-DD") } });
                    }}
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                  />
                </div>
              </div>
            </div>

            <div className="row mt-40">
              <div className="col-md-12">
                <Editor
                  editorState={editorState}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  onEditorStateChange={onEditorStateChange}
                />
                <button className="btn btn-primary mt-2" onClick={() => onSubmit("body")}>Mettre à jour</button>
              </div>
            </div>

            <div className="row mt-40">
              <div className="col-md-8">
                <MultipleValueTextInput
                  onItemAdded={(item) => setTags([...tags, item])}
                  onItemDeleted={(item) => setTags(tags.filter(tag => tag !== item))}
                  name="hashtags"
                  values={tags}
                  placeholder="Ajouter un tag"
                />
                <button className="btn btn-primary mt-2" onClick={() => onSubmit("tags", { target: { value: tags } })}>Mettre à jour</button>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  );
};

export default UpdateArticle;
