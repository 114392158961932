import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Validate from "../../components/form/Validate";

import { useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";

import Login from "../../components/layout/Login";

const LoginPage = ({ loginUser, auth, errors, clearErrors }) => {
  const navigate = useNavigate(); // Correction ici
  
  const [client, setClient] = useState({
    email: "",
    password: "",
    errors: {},
  });

  const [message, setMessage] = useState("");

  useEffect(() => {
    clearErrors(); // Correction : plus besoin de history.listen()
    if (localStorage.loginMessage) {
      setMessage(localStorage.loginMessage);
      localStorage.setItem("loginMessage", "");
    }
  }, [clearErrors]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate("/dashboard"); // Correction ici
    }
    setClient((client) => {
      return { ...client, errors };
    });
  }, [auth, errors, navigate]); // Correction ici

  const handleChange = (e) => {
    setClient({
      ...client,
      [e.target.name]: e.target.value,
    });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const error = { ...client.errors, ...Validate(name, value).errors };
    setClient({ ...client, errors: { ...error } });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = client;
    loginUser({ email, password });
  };

  return (
    <div>
      <Login
        message={message}
        loading={auth.adminLoading}
        client={{ ...client }}
        onBlur={handleBlur}
        onChange={handleChange}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

LoginPage.propTypes = {
  loginUser: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser, clearErrors })(LoginPage);
