import isEmpty from "is-empty";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import MultipleValueTextInput from "react-multivalue-text-input";

const AddArticle = ({
  article,
  onChange,
  onChangeTags,
  onSubmit,
  editorState,
  onEditorStateChange,
  previewPicture,
  categories,
}) => {
  const { category, picture, title, published_date, tags, body, errors } = article;

  let display_valid_button =
    !isEmpty(category) &&
    !isEmpty(picture) &&
    !isEmpty(title) &&
    !isEmpty(published_date) &&
    !isEmpty(tags) &&
    !isEmpty(body);

    const uploadImageCallBack = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve({ data: { link: reader.result } });
        reader.onerror = (error) => reject(error);
      });
    };

  return (
    <div className="content-area single-page-area">
      <div className="single-page-content">
        <div className="page-title">
          <h2>New Article</h2>
        </div>

        <article className="post">
          {/* IMAGE PRINCIPALE */}
          <div className="thumb ta-stroke" style={{ marginBottom: "20px", textAlign: "center" }}>
            <img
              src={!isEmpty(previewPicture) ? previewPicture : "/assets/img/blog/blog-7.webp"}
              alt="Aperçu"
              style={{ maxWidth: "100%", height: "auto", borderRadius: "8px" }}
            />
          </div>

          <div className="form-group" style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "20px" }}>
            <input name="picture" type="file" accept=".jpg,.jpeg,.png" onChange={onChange} />
            <label style={{ color: isEmpty(errors.pic_w_h) ? "green" : "red", marginTop: "8px" }}>
              {isEmpty(errors.pic_w_h) ? "Picture : 850 x 450" : errors.pic_w_h}
            </label>
          </div>

          {/* FORMULAIRE */}
          <div className="entry-content">
            <div className="row">
              {/* CHAMP TITRE */}
              <div className="col-md-12 mt-30">
                <div className="ta-form-group">
                  <span className="icon">
                    <i className="fa fa-text-height"></i>
                  </span>
                  <input name="title" type="text" placeholder="Title" onChange={onChange} className="form-control" />
                  <label style={{ color: "red", marginTop: "5px" }}>{isEmpty(title) ? "Missing title" : ""}</label>
                </div>
              </div>
            </div>

            {/* CATÉGORIE & DATE DE PUBLICATION */}
            <div className="row mt-30" style={{ display: "flex", gap: "20px" }}>
              {/* CATÉGORIE */}
              <div className="col-md-6">
                <div className="ta-form-group">
                  <span className="icon">
                    <i className="fa fa-sun"></i>
                  </span>
                  <select onChange={onChange} name="category" className="form-select">
                    <option value="" selected disabled>Select a category</option>
                    {categories.map((a) => (
                      <option key={a.name} value={a._id}>{a.name}</option>
                    ))}
                  </select>
                  <label style={{ color: "red", marginTop: "5px" }}>{isEmpty(category) ? "Missing category" : ""}</label>
                </div>
              </div>

              {/* DATE DE PUBLICATION */}
              <div className="col-md-6">
                <div className="ta-form-group">
                  <span className="icon">
                    <i className="fa fa-calendar-week"></i>
                  </span>
                  <input name="published_date" onChange={onChange} type="date" className="form-control" />
                  <label style={{ color: "red", marginTop: "5px" }}>{isEmpty(published_date) ? "Missing published date" : ""}</label>
                </div>
              </div>
            </div>

            {/* CONTENU DE L'ARTICLE */}
            <div className="row mt-40">
              <div className="col-md-12">
                <h3 className="mb-20">Content</h3>
                <Editor
                    name="body"
                    editorState={editorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    wrapperStyle={{ border: "1px solid #ccc", padding: "10px", borderRadius: "5px" }}
                    editorStyle={{ height: "300px", padding: "10px" }}
                    onEditorStateChange={onEditorStateChange}
                    onChange={onChange}
                    toolbar={{
                      image: {
                        uploadCallback: uploadImageCallBack, // ✅ Permet d'ajouter une image
                        alt: { present: true, mandatory: false }, // ✅ Option pour l'alt text
                        previewImage: true, // ✅ Afficher l'aperçu avant upload
                        inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                      },
                    }}
                  />
              </div>
            </div>

            {/* TAGS */}
            <div className="row mt-40">
              <div className="col-md-8">
                <h3 className="mb-20">Tags</h3>
                <MultipleValueTextInput
                  onItemAdded={(item) => onChangeTags(item, "Add")}
                  onItemDeleted={(item) => onChangeTags(item, "Delete")}
                  name="hashtags"
                  placeholder="Enter at least one tag"
                />
              </div>
            </div>

            {/* BOUTON DE VALIDATION */}
            <div className="row mt-50">
              <div className="col-md-12 text-center">
                {display_valid_button ? (
                  <input type="submit" className="button btn-send" value="Publish Article" onClick={onSubmit} />
                ) : (
                  <input type="submit" className="button btn-send" value="Fields missing" disabled style={{ backgroundColor: "grey" }} />
                )}
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  );
};

export default AddArticle;
