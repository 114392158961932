import { combineReducers } from "redux";
import errorReducer from "./errorReducers";
import authReducer from "./authReducers";
import dataReducer from "./dataReducers";
import articleReducer from "./articleReducers";
import contactReducer from "./contactReducers";
import subscribeReducer from "./subscribeReducers";
import textReducer from "./textReducers";

export default combineReducers({
  auth: authReducer,
  data: dataReducer,
  article: articleReducer,
  subscribe: subscribeReducer,
  contact: contactReducer,
  text: textReducer,
  errors: errorReducer,
});
