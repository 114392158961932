import React from "react";
import Typewriter from "typewriter-effect";

const Home = () =>{ 

    return (
        <div className="content-area">
            {/* <div className="animated-sections">
              <section data-id="home" className="animated-section start-page"> */}
                <div className="section-content vcentered">

                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="title-block">
                          <h2>Nadia A.</h2>
                          <div className="owl-carousel text-rotation">                                    
                            <div className="item">
                              <div className="sp-subtitle">Data Engineer</div>
                            </div>
                            
                            <div className="item">
                              <div className="sp-subtitle">
                                <Typewriter
                                  options={{
                                    strings: [
                                      "Data Engineer",
                                      "Fullstack Dev. MERN",
                                      "Notion Lover",
                                      "Finance Controller",
                                    ],
                                    autoStart: true,
                                    loop: true,
                                    deleteSpeed: 50, // Vitesse d'effacement
                                    delay: 20, // Vitesse d'écriture
                                  }}
                                />
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>

                {/* </div>
              </section> */}
            </div>
        </div>
    
    
    
    )
}

export default Home;