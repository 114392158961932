//AUTH
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const TOGGLE_USER_LOADING = "TOGGLE_USER_LOADING";
export const TOGGLE_USER_UPDATE = "TOGGLE_USER_UPDATE";
export const UPDATE_USER = "UPDATE_USER";

// ARTICLES
export const GET_PUBLIC_ARTICLES = "GET_PUBLIC_ARTICLES";
export const GET_PRIVATE_ARTICLES = "GET_PRIVATE_ARTICLES";
export const POST_NEW_ARTICLE = "POST_NEW_ARTICLE";
export const UPDATE_ARTICLE = "UPDATE_ARTICLE";
export const DELETE_ARTICLE = "DELETE_ARTICLE";
export const TOGGLE_ARTICLE = "TOGGLE_ARTICLE";
export const ADMIN_DETAILS_ARTICLE = "ADMIN_DETAILS_ARTICLE";
export const PUBLIC_DETAILS_ARTICLE = "PUBLIC_DETAILS_ARTICLE";
export const GET_ARTICLES_BY_TAG = "GET_ARTICLES_BY_TAG";
export const GET_ARTICLES_BY_CATEGORY = "GET_ARTICLES_BY_CATEGORY";
export const ADD_COMMENT = "ADD_COMMENT";
export const ADD_LIKE = "ADD_LIKE";
export const GET_ARTICLE_BY_LINK = "GET_ARTICLE_BY_LINK";
export const TOGGLE_ARTICLE_LOADING = "TOGGLE_ARTICLE_LOADING";
export const TOGGLE_LIKE_ADDING = "TOGGLE_LIKE_ADDING";

// TEXT
export const GET_PRIVATE_TEXT_DETAIL = "GET_PRIVATE_TEXT_DETAIL";
export const GET_PUBLIC_TEXT_DETAIL = "GET_PUBLIC_TEXT_DETAIL";
export const UPDATE_TEXT = "UPDATE_TEXT";
export const TOGGLE_TEXT = "TOGGLE_TEXT";

//CONTACT
export const GET_ALL_CONTACTS = "GET_ALL_CONTACTS";
export const POST_NEW_CONTACT = "POST_NEW_CONTACT";
export const TOGGLE_CONTACT = "TOGGLE_CONTACT";

//SUBSCRIBE
export const POST_SUBSCRIBE = "POST_SUBSCRIBE";
export const GET_ALL_SUBSCRIBE = "GET_ALL_SUBSCRIBE";
export const TOGGLE_SUBSCRIBE = "TOGGLE_SUBSCRIBE";

// DATA
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_ALL_CATEGORIES = "DELETE_ALL_CATEGORIES";
export const GET_CATEGORY_BY_ID = "GET_CATEGORY_BY_ID";


//ERROR
export const SET_ERRORS = "SET_ERRORS";
