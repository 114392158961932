import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";

import Header from "./components/layout/Header";
import HomePage from "./containers/layout/HomePage";
import BlogPage from "./containers/layout/BlogPage";
import BlogDetailPage from "./containers/layout/BlogDetailPage";
import LoginPage from "./containers/layout/LoginPage";
import DashboardPage from "./containers/admin/DashboardPage";
import BlogAdminPage from "./containers/admin/BlogAdminPage";
import BlogDetailAdminPage from "./containers/admin/BlogDetailAdminPage";
import UpdateArticlePage from "./containers/admin/UpdateArticlePage";
import AddArticlePage from "./containers/admin/AddArticlePage";
import SettingsPage from "./containers/admin/SettingsPage";

import { Provider } from "react-redux";
import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";

import { setCurrentClient, logoutClient } from "./actions/authActions";


if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);
  const decoded = jwt_decode(token);
  store.dispatch(setCurrentClient(decoded));

  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutClient());
    window.location.href = "./login";
  }
}

export const handleClick = () => {
  store.dispatch(logoutClient());
};

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className="ta-main-wrapper">
          <Header auth={store.getState().auth} onClick={handleClick} />

          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/blog/:linkFormat" element={<BlogDetailPage />} />

            <Route path="/login" element={<LoginPage />} />

            <Route exact path="/dashboard" element={<PrivateRoute />}>
              <Route path="/dashboard" element={<DashboardPage />} />
            </Route>

            <Route exact path="/dashboard/blog" element={<PrivateRoute />}>
              <Route path="/dashboard/blog" element={<BlogAdminPage />} />
            </Route>


            <Route exact path="/dashboard/settings" element={<PrivateRoute />}>
              <Route path="/dashboard/settings" element={<SettingsPage />} />
            </Route>


            <Route exact path="/dashboard/blog/:linkFormat" element={<PrivateRoute />}>
              <Route path="/dashboard/blog/:linkFormat" element={<BlogDetailAdminPage />} />
            </Route>

            <Route exact path="/admin/updateArticle/:id" element={<PrivateRoute />}>
              <Route path="/admin/updateArticle/:id" element={<UpdateArticlePage />} />
            </Route>

            <Route exact path="/dashboard/blog/new" element={<PrivateRoute />}>
              <Route path="/dashboard/blog/new" element={<AddArticlePage />} />
            </Route>

            <Route path="*" element={<Navigate to={"/"} />} />
          </Routes>
        </div>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
