import {
  GET_PRIVATE_TEXT_DETAIL,
  GET_PUBLIC_TEXT_DETAIL,
  TOGGLE_TEXT,
} from "../actions/types";

const initialState = {
  private_text: {},
  public_text: {},
  text_loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PUBLIC_TEXT_DETAIL:
      return {
        ...state,
        public_text: action.payload,
      };
    case GET_PRIVATE_TEXT_DETAIL:
      return {
        ...state,
        private_text: action.payload,
      };

    case TOGGLE_TEXT:
      return {
        ...state,
        text_loading: !state.text_loading,
      };
    default:
      return state;
  }
}
