import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import {
  SET_CURRENT_USER,
  TOGGLE_USER_LOADING,
  TOGGLE_USER_UPDATE,
  UPDATE_USER,
} from "./types";

import { setErrors } from "./errorActions";

export const registerUser = (userData, history) => (dispatch) => {
  dispatch(toggleUserLoading());
  axios
    .post("/api/users/signup", userData)
    .then((res) => {
      dispatch(toggleUserLoading());
      history("/login");
    })
    .catch((err) => {
      dispatch(setErrors(err));
      dispatch(toggleUserLoading());
    });
};

export const checkregisterUser = (userData) => (dispatch) => {
  dispatch(toggleUserLoading());
  axios
    .post("/api/users/checkSignup", userData)
    .then((res) => {
      dispatch(toggleUserLoading());
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
      dispatch(toggleUserLoading());
    });
};

export const loginUser = (userData) => (dispatch) => {
  dispatch(toggleUserLoading());
  axios
    .post("/api/users/login", userData)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);

      setAuthToken(token);
      const decoded = jwt_decode(token);
      dispatch(setCurrentClient(decoded));
      dispatch(toggleUserLoading());
      window.location.reload();
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
      dispatch(toggleUserLoading());
    });
};

export const updateClient = (data) => (dispatch) => {
  dispatch(toggleUpdateUser());
  axios
    .post(`/api/users/info/update`, { data: data })
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      dispatch(toggleUpdateUser());
    })
    .catch((err) => {
      dispatch(toggleUpdateUser());
      dispatch(setErrors(err.response.data));
    });
};
export const setCurrentClient = (userData) => {
  return {
    type: SET_CURRENT_USER,
    payload: userData,
  };
};

export const toggleUserLoading = () => {
  return {
    type: TOGGLE_USER_LOADING,
  };
};

export const toggleUpdateUser = () => {
  return {
    type: TOGGLE_USER_UPDATE,
  };
};

export const logoutClient = () => (dispatch) => {
  localStorage.removeItem("jwtToken");
  setAuthToken(false);
  dispatch(setCurrentClient({}));
  // window.location.reload();
  window.location.href = "/";
};
