import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import AddArticle from "../../components/admin/AddArticle";
import { EditorState, convertToRaw } from "draft-js";
import { useNavigate } from "react-router-dom";

import { postNewArticle } from "../../actions/articleActions";
import { getAllCategories } from "../../actions/dataActions";

const AddArticlePage = ({ errors, categories, postNewArticle,getAllCategories }) => {
  let history = useNavigate();
  const [article, setArticle] = useState({
    category: "",
    picture: "",
    title: "",
    published_date: "",
    tags: [],
    body: {},
    errors: {},
  });
  

  useEffect(() => {
    getAllCategories();
  }, [getAllCategories]);

  useEffect(() => {
    setArticle((article) => {
      return { ...article, errors };
    });
  }, [errors]);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [previewSource, setPreviewSource] = useState("");
  const [selectedFile, setSelectedFile] = useState("");

  const handleChange = (e) => {
    switch (e.target) {
      case undefined:
        setArticle({ ...article, body: e.blocks });
        break;

      default:
        switch (e.target.name) {
          case "picture":
            const file = e.target.files[0];
            if (!file) return;

            setSelectedFile(file);

            // Affiche l'image redimensionnée dans l'aperçu
            resizeImage(file, 850, 450, (resizedImage) => {
              setPreviewSource(resizedImage);
            });

            // Stocke l'image originale pour l'envoi au backend
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () =>
              setArticle({ ...article, picture: reader.result });

            break;
          case "body":
            setArticle({
              ...article,
              [e.target.name]: JSON.stringify(
                convertToRaw(editorState.getCurrentContent())
              ),
            });
            break;
          default:
            setArticle({
              ...article,
              [e.target.name]: e.target.value,
            });
            break;
        }
        break;
    }
  };

  const handleChangeTags = (e, type) => {
    switch (type) {
      case "Add":
        setArticle({ ...article, tags: [...article.tags, e] });
        break;
      case "Delete":
        setArticle({ ...article, tags: article.tags.filter((a) => a !== e) });
        break;
      default:
        break;
    }
  };

  const handleChangeContent = (e) => {
    setEditorState(e);
  };

  const handleSubmit = () => {
    article.body = JSON.stringify(
      convertToRaw(editorState.getCurrentContent())
    );

    const { category, picture, title, body, tags, published_date } = article;
    postNewArticle(
      { category, picture, title, body, tags, published_date },
      history
    );
  };

  return (
    <div>
      <AddArticle
        article={article}
        onChange={handleChange}
        onChangeTags={handleChangeTags}
        onSubmit={handleSubmit}
        editorState={editorState}
        onEditorStateChange={handleChangeContent}
        previewPicture={previewSource}
        // DATA
        categories={categories}
      />

      {/* Aperçu de l'image redimensionnée */}
      {previewSource && (
        <img
          src={previewSource}
          alt="Aperçu"
          style={{
            width: "100%",
            maxWidth: "850px",
            height: "auto",
            borderRadius: "8px",
            marginTop: "10px",
          }}
        />
      )}
    </div>
  );
};

AddArticlePage.propTypes = {
  postNewArticle: PropTypes.func.isRequired,
  getAllCategories:PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  categories: state.data.categories,
});

export default connect(mapStateToProps, { postNewArticle,getAllCategories })(AddArticlePage);

/**
 * Fonction pour redimensionner une image à 850x450 pixels avant l'affichage
 */
const resizeImage = (file, maxWidth = 850, maxHeight = 450, callback) => {
  const reader = new FileReader();

  reader.readAsDataURL(file);
  reader.onload = (event) => {
    const img = new Image();
    img.src = event.target.result;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Définir les dimensions du canvas
      canvas.width = maxWidth;
      canvas.height = maxHeight;

      // Dessiner l’image redimensionnée sur le canvas
      ctx.drawImage(img, 0, 0, maxWidth, maxHeight);

      // Convertir le canvas en Base64
      const resizedImage = canvas.toDataURL("image/jpeg", 0.9);

      callback(resizedImage); // Retourne l’image redimensionnée
    };
  };
};
