import {
  GET_PUBLIC_ARTICLES,
  GET_PRIVATE_ARTICLES,
  ADMIN_DETAILS_ARTICLE,
  TOGGLE_ARTICLE,
  PUBLIC_DETAILS_ARTICLE,
  GET_ARTICLES_BY_TAG,
  GET_ARTICLES_BY_CATEGORY,
  GET_ARTICLE_BY_LINK,
  TOGGLE_LIKE_ADDING
} from "../actions/types";

const initialState = {
  all_public_articles: {},
  admin_article_details: {},
  public_article_details: {},
  all_private_articles: {},
  article_by_tag: [],
  article_by_category: [],
  article_by_link:[],
  article_loading: false,
  addLikeLoading:false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ARTICLES_BY_CATEGORY:
      return {
        ...state,
        article_by_category: [...action.payload],
      };
    case GET_ARTICLES_BY_TAG:
      return {
        ...state,
        article_by_tag: [...action.payload],
      };

    case GET_ARTICLE_BY_LINK:
      return {
        ...state,
        article_by_link:[...action.payload]
      };

    case PUBLIC_DETAILS_ARTICLE:
      return {
        ...state,
        public_article_details: action.payload,
      };

    
    case TOGGLE_LIKE_ADDING:
        return{
          ...state,
          addLikeLoading: !state.addLikeLoading
        };
    case ADMIN_DETAILS_ARTICLE:
      return {
        ...state,
        admin_article_details: action.payload,
      };
    case GET_PUBLIC_ARTICLES:
      return {
        ...state,
        all_public_articles: action.payload,
      };
    case GET_PRIVATE_ARTICLES:
      return {
        ...state,
        all_private_articles: action.payload,
      };
    case TOGGLE_ARTICLE:
      return {
        ...state,
        article_loading: !state.article_loading,
      };
    default:
      return state;
  }
}
