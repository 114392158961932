import axios from "axios";

import {
  GET_PUBLIC_ARTICLES,
  GET_PRIVATE_ARTICLES,
  POST_NEW_ARTICLE,
  UPDATE_ARTICLE,
  DELETE_ARTICLE,
  TOGGLE_ARTICLE,
  GET_ARTICLES_BY_TAG,
  GET_ARTICLES_BY_CATEGORY,
  ADMIN_DETAILS_ARTICLE,
  PUBLIC_DETAILS_ARTICLE,
  ADD_LIKE,
  ADD_COMMENT,
  GET_ARTICLE_BY_LINK,
  TOGGLE_LIKE_ADDING
} from "./types";

import { setErrors } from "./errorActions";

//A#1.1 : get all articles for admin dashboard
export const getAdminArticles = () => (dispatch) => {
  axios
    .get(`/api/articles/admin/`)
    .then((res) => {
      dispatch({
        type: GET_PRIVATE_ARTICLES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
    });
};

//A#1.3 : get all articles FILTER BY TAG
export const getArticlesByTag = (data) => (dispatch) => {
  axios
    .post(`/api/articles/tag/`, data)
    .then((res) => {
      dispatch({
        type: GET_ARTICLES_BY_TAG,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
    });
};

export const getArticleByLinkFormat = linkFormat => dispatch => {
  dispatch(toggleUpdateArticle());
  axios
     .get(`/api/articles/article/link/${linkFormat}`)
     .then(res => {
        dispatch({
           type: GET_ARTICLE_BY_LINK,
           payload: res.data
        });
        dispatch(toggleUpdateArticle());
     })

     .catch(err => {
        dispatch(setErrors(err));
        dispatch(toggleUpdateArticle());
     });
};



//A#1.4 : get all articles FILTER BY CATEGORY
export const getArticlesByCategory = (data) => (dispatch) => {
  axios
    .post(`/api/articles/category/`, data)
    .then((res) => {
      dispatch({
        type: GET_ARTICLES_BY_CATEGORY,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
    });
};

//A#1.2 : get all articles for website
export const getPublicArticles = (data) => (dispatch) => {
  axios
    .post(`/api/articles/`, data)
    .then((res) => {
      dispatch({
        type: GET_PUBLIC_ARTICLES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
    });
};

//add like to an article
export const addLike = (id,author,adding_like) => dispatch => {
  dispatch(toggleAddLikeLoading());
  axios
     .patch(`/api/articles/addLike`,{id:id,author:author,nb_like:adding_like})
     .then(res => {
        dispatch({
           type: ADD_LIKE,
           payload: res.data
        });
        dispatch(toggleAddLikeLoading());


     })
     .catch(err => {
        dispatch(setErrors(err));
        dispatch(toggleAddLikeLoading());

     });
};



//A#2 : post new article
export const postNewArticle = (data, history) => (dispatch) => {
  axios
    .post(`/api/articles/admin/new`, { data })
    .then((res) => {
      dispatch({
        type: POST_NEW_ARTICLE,
        payload: res.data,
      });
      history("/dashboard/blog");
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
    });
};

//A#3 : update an article
export const updateArticle = (data) => (dispatch) => {
  dispatch(toggleUpdateArticle());
  axios
    .post(`/api/articles/admin/update`, data)
    .then((res) => {
      dispatch({
        type: UPDATE_ARTICLE,
        payload: res.data,
      });
      dispatch(toggleUpdateArticle());
    })
    .catch((err) => {
      dispatch(toggleUpdateArticle());
      dispatch(setErrors(err.response.data));
    });
};

//A#3 : add comment
export const addComment = (data) => (dispatch) => {
  dispatch(toggleUpdateArticle());
  axios
    .post(`/api/articles/comment/update`, data)
    .then((res) => {
      dispatch({
        type: ADD_COMMENT,
        payload: res.data,
      });
      dispatch(toggleUpdateArticle());
    })
    .catch((err) => {
      dispatch(toggleUpdateArticle());
      dispatch(setErrors(err.response.data));
    });
};

//A#5 : add like
export const postLikeArticle = (data) => (dispatch) => {
  dispatch(toggleUpdateArticle());
  axios
    .post(`/api/articles/like`, data)
    .then((res) => {
      dispatch({
        type: ADD_LIKE,
        payload: res.data,
      });
      dispatch(toggleUpdateArticle());
    })
    .catch((err) => {
      dispatch(toggleUpdateArticle());
      dispatch(setErrors(err.response.data));
    });
};
//A#4 : delete an article
export const deleteArticle = (data) => (dispatch) => {
  dispatch(toggleUpdateArticle());
  axios
    .post(`/api/articles/admin/delete`, data)
    .then((res) => {
      dispatch(toggleUpdateArticle());
      dispatch({
        type: DELETE_ARTICLE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(toggleUpdateArticle());
      dispatch(setErrors(err.response.data));
    });
};

//A#5 : get private details article
export const getAdminArticlesDetails = (data) => (dispatch) => {
  console.log({data})
  axios
    .post(`/api/articles/admin/details`, data)
    .then((res) => {
      dispatch({
        type: ADMIN_DETAILS_ARTICLE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
    });
};

//A#6 : get public details article
export const getPublicArticlesDetails = (data) => (dispatch) => {
  axios
    .post(`/api/articles/details`, data)
    .then((res) => {
      dispatch({
        type: PUBLIC_DETAILS_ARTICLE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(setErrors(err.response.data));
    });
};

export const toggleUpdateArticle = () => {
  return {
    type: TOGGLE_ARTICLE,
  };
};

export const toggleAddLikeLoading=()=> {
  return {
     type: TOGGLE_LIKE_ADDING
  }
}