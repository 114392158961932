import React from "react";
import Home from "../../components/layout/Home";


const HomePage = () => {

   return (
    
          <Home />

   )
};




export default HomePage