import React, { useState } from "react";

const BlogAdmin = ({ articles,onDelete,onActive,categories}) => {
    // State pour la recherche
    const [searchTerm, setSearchTerm] = useState("");

  if (!articles || !Array.isArray(articles)) {
    return <p>Aucun article trouvé.</p>;
  }



  // Fonction pour mettre à jour la recherche
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  // Tri des articles par date (plus récents en premier)
  const sortedArticles = articles
    .slice()
    .sort((a, b) => new Date(b.published_date) - new Date(a.published_date));

  // Filtrage des articles en fonction du terme de recherche
  const filteredArticles = sortedArticles.filter((article) =>
    article.title.toLowerCase().includes(searchTerm)
  );

  return (
    <div className="content-area">
      <section className="animated-section">
        <div className="section-content">
          <div className="page-title">
            <h2>Admin blog</h2>
          </div>
          
          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: "10px" }}>
            <a href="/dashboard/blog/new"
              className="ci-icon"
              style={{ 
                  color: "green", 
                  fontSize: "18px",
                  display: "flex", 
                  alignItems: "center", 
                  gap: "8px", 
                  textDecoration: "none",
                  fontWeight: "bold"
              }}>
              <i className="lnr lnr-file-empty" style={{ fontSize: "24px" }}></i>
              <span>New</span>
            </a>
          </div>



          {/* Barre de recherche */}
          <div className="search-bar">
            <input
              type="text"
              placeholder="Rechercher par titre..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="form-control"
            />
          </div>

        {/* Espacement */}
        <div className="spacer"></div>



          {/* Liste des articles */}
          <div className="row">
            <div className="col-xs-12 col-sm-12">
              <div className="blog-masonry two-columns clearfix">
                {filteredArticles.length > 0 ? (
                  filteredArticles.map((article, index) => {
                    
                    const category = categories.find(cat => cat._id.toString() === article.category)
                    const categoryName = category?.name || "Non classé"
                    const categoryColor = category?.color || "#ccc"
                    
                    return (

       
                    
                    <div key={article.id} className={`item post-${index + 1}`}>
                      <div className="blog-card">
                        <div className="media-block">
                          <div style={{backgroundColor:categoryColor}} className="category">
                            <a  style={{ backgroundColor: categoryColor, color: "#fff", padding: "5px 10px", borderRadius: "5px", display: "inline-block" }} href="#" title={`Voir tous les articles dans ${article.category}`}>
                              {categoryName}
                            </a>
                          </div>
                          <a href={`/dashboard/blog/${article.linkFormat}`}>
                            <img
                              src={article.picture?.url || "default.jpg"}
                              className="size-blog-masonry-image-two-c"
                              alt={article.title}
                              title={article.title}
                            />
                            <div className="mask"></div>
                          </a>
                        </div>
                        <div className="post-info">
                          <div className="post-date">
                            {new Date(article.published_date).toLocaleDateString("fr-FR", {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })}
                          </div>


                          <div className="title-actions">
                            <a href={`/blog/${article.linkFormat}`} className="blog-title">
                              <h4 className="blog-item-title">{article.title}</h4>
                            </a>
                                                                                         
                            <div className="spacer"></div>

                            <div className="icons">
                              <div className="ci-icon pencil" data-tooltip="Update">
                              <a href={`/admin/updateArticle/${article.id}`} className="ci-icon" style={{ color: "green" }}>
                                <i className="lnr lnr-pencil"></i>
                              </a>

                              </div>
                              <div className="ci-icon heart" data-tooltip="Stats">
                                <i className="lnr lnr-heart-pulse"></i>
                              </div>
                              <div className={article.isActive?`ci-icon moon`:`ci-icon sun`} data-tooltip={article.isActive?`Archiver`:`Réactiver`} onClick={()=>onActive(article.id)}>
                                {article.isActive?<i className="lnr lnr-moon"></i>:<i className="lnr lnr-sun" style={{color:'#E0BC00'}}></i>}
                              </div>
                              <div className="ci-icon trash" data-tooltip="Delete" onClick={() => onDelete(article.id)}>
                                  <i className="lnr lnr-trash" style={{ cursor: "pointer", color: "red" }}></i>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  )})
                ) : (
                  <p>Aucun article ne correspond à votre recherche.</p>
                )}
              
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BlogAdmin;
