import React, { useEffect, useState} from "react";
import PropTypes from "prop-types";

import {getArticleByLinkFormat,addLike} from "../../actions/articleActions";
import { EditorState, ContentState, convertFromRaw} from 'draft-js';
import {connect} from "react-redux";

import {useParams} from "react-router-dom"

import BlogDetailAdmin from "../../components/admin/BlogDetailAdmin";

import {getAllCategories} from "../../actions/dataActions"

const BlogDetailAdminPage = ({
   article,
   getArticleByLinkFormat,
   addLike,
   addLikeLoading,
   getAllCategories,
   categories
}) => {

  const { linkFormat } = useParams();
  const [isLiked, setIsLiked] = useState(false)

   useEffect(() => {
      getArticleByLinkFormat(linkFormat)
   }, [linkFormat, getArticleByLinkFormat,addLikeLoading]);

   useEffect(()=>{
      getAllCategories()
   }, [getAllCategories])


   const len_articles = [...Array(Object.keys(article).length).keys()]
   const view_article = len_articles.map(a=>Object.values(article)[a])
   const article_text = !view_article[4]?EditorState.createWithContent(ContentState.createFromText('Article')):EditorState.createWithContent(convertFromRaw(JSON.parse(view_article[4])))
   
   if (Object.keys(article).length === 0) return <div />;


   const handleAddLike = ()=> {
   let adding_like = article.nb_like+1

    addLike(article._id,article.author,adding_like)

    setIsLiked(!isLiked)
   };


   return (

         <BlogDetailAdmin
            article={article}
            categories={categories}

            //likes
            add_like={handleAddLike}
            disable_like={isLiked}
         />

   );
};
const mapStateToProps = state => ({
   article: state.article.article_by_link,
   auth:state.auth.isAuthenticated,
   addLikeLoading:state.article.addLikeLoading,
   categories:state.data.categories
});
BlogDetailAdminPage.propTypes = {
   auth:PropTypes.bool.isRequired,
   article: PropTypes.object.isRequired,
   getArticleByLinkFormat: PropTypes.func.isRequired,
   addLike:PropTypes.func.isRequired,
   getAllCategories:PropTypes.func.isRequired
};

export default connect(
   mapStateToProps,
   {getArticleByLinkFormat,addLike,getAllCategories}
)(BlogDetailAdminPage);