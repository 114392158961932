import React,{useState} from 'react';
import { connect } from "react-redux";

import Calendar from "react-calendar";
import "./../../utils/Calendar.css";
import moment from "moment";
import "moment/locale/fr";
import isEmpty from "is-empty";

import { useLocation } from "react-router-dom";


const Header = ({ auth, articles,onClick}) => {
  const isAuth = auth.isAuthenticated;
  const location = useLocation();
  const currentPath = location.pathname; // Contient l'URL relative


  const [publish, setPublish] = useState({
    date: "",
  });

  const last_day = moment().add(2, "M").endOf("month").format("MM-DD-YYYY");
  const published_dates = isEmpty(articles)
    ? ""
    : articles.filter((a) => a.isActive).map((b) => b.published_date);

  const date_selected = isEmpty(publish.date)
    ? moment().format("MM/DD/yyyy")
    : moment(publish.date).format("MM/DD/yyyy");


  // *** PLANNING ***
  //  const handleChangeCalendarDate = (e) => {
  //   setPublish((publish) => ({ ...publish, date: e }));
  // };

  // const handleOnDelete = (e) => {
  //   deleteArticle({ id: e });
  // };

  // const handleOnActive = (e) => {
  //   updateArticle({ type: "active", data: e });
  // };

  // const handleOnPopular = (e) => {
  //   updateArticle({ type: "isPopular", data: e });
  // };

  

  return (
    <>
      {isAuth ? (
        <header id="site_header" className="header mobile-menu-hide">
          <div className="header-content">
            <div className="header-photo">
              <img src="/assets/img/nab_photo.jpeg" alt="Nadia Abdallah" />
            </div>
            <div className="header-titles">
              <h2>Administration</h2>
            </div>
          </div>

          <ul className="main-menu">
            <li className="active">
              <a href="/dashboard" className="nav-anim">
                <span className="menu-icon lnr lnr-home" style={{color:'orange'}}></span>
                <span className="link-text">Home</span>
              </a>
            </li>
            <li>
              <a href="/dashboard/blog" className="nav-anim">
                <span className="menu-icon lnr lnr-book" style={{color:'blue'}}></span>
                <span className="link-text">Blog</span>
              </a>
            </li>
            <li>
              <a href="dashboard/settings" className='nav-anim'>
                <span className="menu-icon lnr lnr-cog" style={{color:'violet'}}></span>
              </a>
            </li>
            <li>
              <a href="/#" onClick={(e) => {
                  e.preventDefault();
                  onClick(); // Fonction de déconnexion
                }} className="nav-anim">
                <span className="menu-icon lnr lnr-exit" style={{color:'red'}}></span>
                <span className="link-text">Logout</span>
              </a>
            </li>

          </ul>

         
           {currentPath ==="/dashboard/blog" && <Calendar
              maxDate={new Date(last_day)}
              // onChange={onChangeCalendar}
              locale="fr-FR"
              tileClassName={({ date, view }) => {
                if (
                  published_dates.includes(
                    moment(date).format("YYYY-MM-DD")
                  )
                ) {
                  return "publication";
                }
              }}
            />}

        

          <div className="copyrights">© {moment().year()} All rights reserved.</div>
        </header>
      ) : (
        <header id="site_header" className="header mobile-menu-hide">
          <div className="header-content">
            <div className="header-photo">
              <img src="/assets/img/nab_photo.jpeg" alt="Nadia Abdallah" />
            </div>
            <div className="header-titles">
              <h2>Nadia A.</h2>
              <h4>Data Lover</h4>
            </div>
          </div>

          <ul className="main-menu">
            <li className="active">
              <a href="/" className="nav-anim">
                <span className="menu-icon lnr lnr-home"></span>
                <span className="link-text">Home</span>
              </a>
            </li>
            <li>
              <a href="/blog" className="nav-anim">
                <span className="menu-icon lnr lnr-book"></span>
                <span className="link-text">Blog</span>
              </a>
            </li>
          </ul>

          <div className="social-links">
            <ul>
            <li>
              <a
                href="https://www.linkedin.com/in/nadia-abdallah-581a2048/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/NadNadou"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-github"></i>
              </a>
            </li>

              {/* <li><a href="#" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
              <li><a href="#" target="_blank"><i className="fab fa-twitter"></i></a></li> */}
            </ul>
          </div>

          {/* <div className="header-buttons">
            <a href="#" target="_blank" className="btn btn-primary">Download CV</a>
          </div> */}

          <div className="copyrights">© {moment().year()} All rights reserved.</div>
        </header>
      )}

      {/* Mobile Navigation */}
      <div className="menu-toggle">
        <span></span>
        <span></span>
        <span></span>
      </div>

    </>
  );
};

Header.propTypes = {
};

const mapStateToProps = (state) => ({
  articles: state.article.all_private_articles,
});

export default connect(mapStateToProps)(Header);
