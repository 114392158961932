import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Input from "../form/Input";

import {
   Card,
   Button,
   Form,
   Alert
} from "react-bootstrap";

const Login = ({ message, loading, client, onChange, onBlur, onSubmit }) => {
   const { email, password, errors } = client;
   return (
    <div className="content-area">
        <section className="animated-section">
            <div className="section-content">
                <div className="page-title">
                <h2>Login</h2>
                </div>

                <div className="row">
                <div className="col-xs-12 col-sm-8">
                <Form noValidate onSubmit={onSubmit} className="controls">
      <div className="fields clearfix">
        <div className="left-column">
          <div className="form-group form-group-with-icon">

            {/* Message de succès */}
            {message?.length > 0 && <Alert variant="success">{message}</Alert>}

            {/* Champ Email */}
            <div className="form-group form-group-with-icon">
              <input
                id="form_email"
                type="email"
                name="email"
                className="form-control"
                required
                data-error="Valid email is required."
                value={email}
                onChange={onChange}
                onBlur={onBlur}
              />
              <label>Email Address</label>
              <div className="form-control-border"></div>
              <div className="help-block with-errors">
                {errors?.email && <span className="text-danger">{errors.email}</span>}
              </div>
            </div>

            {/* Champ Mot de passe */}
            <div className="form-group form-group-with-icon">
              <input
                id="form_password"
                type="password"
                name="password"
                className="form-control"
                required
                data-error="Valid password is required."
                value={password}
                onChange={onChange}
                onBlur={onBlur}
              />
              <label>Mot de passe</label>
              <div className="form-control-border"></div>
              <div className="help-block with-errors">
                {errors?.password && <span className="text-danger">{errors.password}</span>}
              </div>
            </div>

          </div>
        </div>
      </div>

      {/* Bouton de soumission */}
      <input type="submit" className="button btn-send" value="Se connecter" disabled={loading} />
    </Form>

                
                </div>
                </div>

            </div>
        </section>
    </div>
   );
};

Login.propTypes = {
   onSubmit: PropTypes.func.isRequired,
   onChange: PropTypes.func.isRequired,
   onBlur: PropTypes.func.isRequired,
   client: PropTypes.object.isRequired,
   message: PropTypes.string.isRequired,
   loading: PropTypes.bool.isRequired
};

export default Login;