import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import isEmpty from "is-empty";
import moment from "moment";

import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { useParams } from "react-router-dom";
import UpdateArticle from "../../components/admin/UpdateArticle";

import {getAdminArticlesDetails,updateArticle} from "../../actions/articleActions"
import {getAllCategories} from "../../actions/dataActions"

const UpdateArticlePage = ({
  errors,
  all_categories,
  article_loading,
  updateArticle,
  getAdminArticlesDetails,
  admin_article_details,
  getAllCategories,categories
}) => {
  const article_id = useParams().id;

  const [article, setArticle] = useState({
    category: "",
    picture: "",
    title: "",
    published_date: "",
    tags: [],
    body: {},
    errors: { pic_size: "", pic_w_h: "" },
  });

  useEffect(()=>{
    getAllCategories()
  },[getAllCategories])

  useEffect(() => {
    setArticle((article) => {
      return { ...article, errors };
    });
  }, [errors]);

  useEffect(() => {
    getAdminArticlesDetails({ data: article_id });
  }, [article_id,getAdminArticlesDetails,article_loading]);

  const [editorState, setEditorState] = useState();

  // Permet d'initiliaser l'Editor

  useEffect(() => {
    const state = isEmpty(admin_article_details)
      ? EditorState.createEmpty()
      : EditorState.createWithContent(
          convertFromRaw(JSON.parse(admin_article_details[0].body))
        );
    setEditorState(state);
  }, [admin_article_details]);

  const [previewSource, setPreviewSource] = useState("");
  const [selectedFile, setSelectedFile] = useState("");

  const handleChange = (e) => {
    switch (e.target) {
      case undefined:
        setArticle({ ...article, body: e.blocks });
        break;

      default:
        switch (e.target.name) {
          case "picture":
            const file = e.target.files[0];
            previewFile(file);
            setSelectedFile(file);

            const reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onloadend = () =>
              setArticle({ ...article, picture: reader.result });

            break;
          case "body":
            setArticle({
              ...article,
              [e.target.name]: JSON.stringify(
                convertToRaw(editorState.getCurrentContent())
              ),
            });
            break;
          default:
            setArticle({
              ...article,
              [e.target.name]: e.target.value,
            });
            break;
        }
        break;
    }
  };

  const handleChangeTags = (e, type) => {
    switch (type) {
      case "Add":
        setArticle({ ...article, tags: [...article.tags, e] });
        break;
      case "Delete":
        setArticle({ ...article, tags: article.tags.filter((a) => a !== e) });
        break;
      default:
        break;
    }
  };

  const handleChangeContent = (e) => {
    setEditorState(e);
  };

  const previewFile = (file) => {
    const reader = new FileReader();
    let pic_size;

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource(reader.result);
    };

    // SIZE CHECK
    Math.round(file.size / 1000) >= 2000
      ? (pic_size = "Please upload a file smaller than 2 Mo")
      : (pic_size = "");
    setArticle({ ...article, errors: { pic_size } });

    // HEIGHT and WIDTH CHECK
    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = () => {
        const { height, width } = image;
        if (height !== 450 || width !== 850) {
          article.errors.pic_w_h = "L'image doit être au format 850px x 450px";
        }
      };
    };
  };

  const handleSubmit = (type, data) => {
    let payload = { id: admin_article_details[0].id };

    switch (type) {
      case "picture":
        payload = { ...payload, picture: article.picture };
        break;
      case "title":
        payload = { ...payload, title: article.title };
        break;
      case "category":
        payload = { ...payload, category: data.target.value };
        break;
      case "published_date":
        payload = { ...payload, published_date: moment(data).format("YYYY-MM-DD") };
        break;
      case "tags":
        payload = { ...payload, tags: article.tags };
        break;
      case "body":
        payload = { ...payload, body: JSON.stringify(convertToRaw(editorState.getCurrentContent())) };
        break;
      case "active":
      case "isPopular":
        payload = { ...payload, [type]: !admin_article_details[0][type] };
        break;
      default:
        return;
    }

    updateArticle({ type, data: payload });
  };

  return (
    <UpdateArticle
      onChange={handleChange}
      onChangeTags={handleChangeTags}
      onSubmit={handleSubmit}
      editorState={editorState}
      onEditorStateChange={handleChangeContent}
      previewPicture={previewSource}
      // DATA
      all_categories={categories}
      article_details={admin_article_details}
    />
  );
};

UpdateArticlePage.propTypes = {
  updateArticle: PropTypes.func.isRequired,
  getAdminArticlesDetails: PropTypes.func.isRequired,
  article_loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  categories: state.data.categories,
  article_loading: state.article.article_loading,
  admin_article_details: state.article.admin_article_details,
});

export default connect(mapStateToProps, {
  updateArticle,
  getAdminArticlesDetails,getAllCategories
})(UpdateArticlePage);
