import { GET_ALL_CONTACTS, TOGGLE_CONTACT } from "../actions/types";

const initialState = {
  all_contacts: {},
  contact_loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CONTACTS:
      return {
        ...state,
        all_contacts: action.payload,
      };
    case TOGGLE_CONTACT:
      return {
        ...state,
        contact_loading: !state.contact_loading,
      };
    default:
      return state;
  }
}
