import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import BlogAdmin from "../../components/admin/BlogAdmin";
import {
  deleteArticle,
  getAdminArticles,
  updateArticle,
} from "../../actions/articleActions";
import { getAllCategories } from "../../actions/dataActions";


const BlogAdminPage = ({
  deleteArticle,
  getAdminArticles,
  updateArticle,
  article_loading,
  all_private_articles,
  getAllCategories,
  categories
}) => {
  const [publish, setPublish] = useState({
    date: "",
  });
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    getAdminArticles();
  }, [getAdminArticles, article_loading]);

  useEffect(()=>{
    getAllCategories()
  },[getAllCategories])

  const handleModal = () => {
    setDisplay((display) => !display);
  };

  // *** PLANNING ***
  const handleChangeCalendarDate = (e) => {
    setPublish((publish) => ({ ...publish, date: e }));
  };

  const handleOnDelete = (e) => {
    const confirmDelete = window.confirm("Voulez-vous vraiment supprimer cet article ?");

    if (confirmDelete) {
      deleteArticle({ id: e });
    }    
  };


  const handleOnActive = (e) => {
    const confirmeArchive = window.confirm("Voulez-vous vraiment archiver cet article ?");

    if (confirmeArchive){
      updateArticle({ type: "active", data: e });
    }


  };

  const handleOnPopular = (e) => {
    updateArticle({ type: "isPopular", data: e });
  };

  return (
    <BlogAdmin
      articles={all_private_articles}
      categories={categories}
      onDelete={handleOnDelete}
      onActive={handleOnActive}
      onPopular={handleOnPopular}
      onChangeCalendar={handleChangeCalendarDate}
      publish={publish}
      onChangeModal={handleModal}
      displayModal={display}
    />
  );
};

BlogAdminPage.propTypes = {
  deleteArticle: PropTypes.func.isRequired,
  getAdminArticles: PropTypes.func.isRequired,
  updateArticle: PropTypes.func.isRequired,
  getAllCategories:PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  all_private_articles: state.article.all_private_articles,
  article_loading: state.article.article_loading,
  categories:state.data.categories
});

export default connect(mapStateToProps, {
  deleteArticle,
  getAdminArticles,
  updateArticle,getAllCategories
})(BlogAdminPage);
