import React from "react";

const Settings = ({
  onSubmitNewCat,
  name,
  setName,
  color,
  setColor,
  message,
  categories,
  deleteCategory,
  editMode,
  setEditMode,
  selectedCategory,
  handleEdit,
}) => {
  return (
    <div className="content-area">
      <section className="animated-section">
        <div className="section-content">
          <div className="page-title">
            <h2>Settings</h2>
          </div>

          <div className="row">
            <div className="col-xs-6 col-sm-6">
              {/* ➤ 📌 Formulaire d'ajout/mise à jour de catégorie */}
              <form onSubmit={onSubmitNewCat} className="category-form">
                <div className="form-group">
                  <label>Nom de la catégorie :</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    placeholder="Ex: Technologie"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label>Couleur associée :</label>
                  <input
                    type="color"
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                    className="color-picker"
                  />
                </div>

                <button type="submit" className="btn btn-primary">
                  {editMode ? "Mettre à jour" : "Ajouter"}
                </button>

                {editMode && (
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      setEditMode(false);
                      setName("");
                      setColor("#000000");
                    }}
                  >
                    Annuler
                  </button>
                )}

                {message && <p className="feedback-message">{message}</p>}
              </form>

              {/* ➤ 📌 Liste des catégories existantes */}
              <div className="categories-list">
                <h3>Catégories existantes</h3>
                {categories.length === 0 ? (
                  <p>Aucune catégorie disponible.</p>
                ) : (
                  <ul>
                    {categories.map((category) => (
                      <li key={category._id} style={{ color: category.color, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span
                            className="category-color"
                            style={{
                              backgroundColor: category.color,
                              width: "16px",
                              height: "16px",
                              borderRadius: "50%",
                              display: "inline-block",
                              marginRight: "10px",
                            }}
                          ></span>
                          {category.name}
                        </div>

                        {/* ➤ 📌 Icônes Modifier et Supprimer */}
                        <div className="icons" style={{ display: "flex", gap: "10px" }}>
                          <div
                            className="ci-icon pencil"
                            data-tooltip="Modifier"
                            onClick={() => handleEdit(category)}
                            style={{ cursor: "pointer", color: "green" }}
                          >
                            <i className="lnr lnr-pencil"></i>
                          </div>

                          <div
                            className="ci-icon trash"
                            data-tooltip="Supprimer"
                            onClick={() => deleteCategory(category._id)}
                            style={{ cursor: "pointer", color: "red" }}
                          >
                            <i className="lnr lnr-trash"></i>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Settings;