import React from "react";

const BlogDetailAdmin = ({ article,categories }) => { 
  // Vérifier que article est bien un tableau et non vide
  if (!Array.isArray(article) || article.length === 0) {
    return <div className="content-area single-page-area"><p>Error: No article found.</p></div>;
  }

  // Récupérer le premier article du tableau
  const currentArticle = article[0];

  // Extraction et parsing du texte depuis body
  let parsedBody = [];
  let parsedEntityMap = {};

  try {
    const parsedContent = JSON.parse(currentArticle.body);
    parsedBody = parsedContent.blocks || [];
    parsedEntityMap = parsedContent.entityMap || {};
  } catch (error) {
    console.error("Error parsing body content:", error);
  }


  // Fonction pour afficher le texte avec le bon style
  const renderBlock = (block, index) => {
    switch (block.type) {
      case "header-one":
        return <h1 key={index} style={{ textAlign: block.data["text-align"] || "left" }}>{block.text}</h1>;
      case "header-two":
        return <h2 key={index} style={{ textAlign: block.data["text-align"] || "left" }}>{block.text}</h2>;
      case "header-three":
        return <h3 key={index} style={{ textAlign: block.data["text-align"] || "left" }}>{block.text}</h3>;
      case "header-four":
        return <h4 key={index} style={{ textAlign: block.data["text-align"] || "left" }}>{block.text}</h4>;
      case "header-five":
        return <h5 key={index} style={{ textAlign: block.data["text-align"] || "left" }}>{block.text}</h5>;
      case "header-six":
        return <h6 key={index} style={{ textAlign: block.data["text-align"] || "left" }}>{block.text}</h6>;
      case "blockquote":
        return (
          <blockquote key={index} className="quote">
            {block.text}
            <footer className="quote-author">
              <span>{currentArticle.author || "Unknown Author"}</span>
            </footer>
          </blockquote>
        );
      case "code-block":
        return (
          <pre key={index} style={{ background: "#f4f4f4", padding: "10px", borderRadius: "5px", overflowX: "auto" }}>
            <code>{block.text}</code>
          </pre>
        );
      case "atomic": // 📌 GESTION DES IMAGES
        if (block.entityRanges.length > 0) {
          const entityKey = block.entityRanges[0].key;
          const entity = parsedEntityMap[entityKey]; // Récupérer les données de l'image
          if (entity?.type === "IMAGE") {
            return (
              <div key={index} style={{ textAlign: "center", margin: "20px 0" }}>
                <img src={entity.data.src} alt="Inserted" style={{ maxWidth: "100%", borderRadius: "8px" }} />
              </div>
            );
          }
        }
        return null;
      default:
        return <p key={index} style={{ textAlign: block.data["text-align"] || "left" }}>{block.text}</p>;
    }
  };
  const category = categories.find(cat => cat._id.toString() === currentArticle.category);
  const categoryName = category?.name || "Non classé";
  const categoryColor = category?.color || "#ccc";



  return (
    <div className="content-area single-page-area">
      <div className="single-page-content">
        <article className="post">
          {/* IMAGE PRINCIPALE */}
          <div className="post-thumbnail">
            <img src={currentArticle.picture.url} alt={currentArticle.title} />
          </div>

          {/* CONTENU */}
          <div className="post-content">
            <header className="entry-header">
              <div className="entry-meta entry-meta-top">
              <span
                style={{
                  backgroundColor: categoryColor,
                  color: "black",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  display: "inline-block",
                  fontWeight: "bold"
                }}
              >
                <a href={`#${categoryName}`} rel="category tag" style={{ color: "inherit", textDecoration: "none" }}>
                  {categoryName}
                </a>
              </span>

              </div>
              <h2 className="entry-title">{currentArticle.title}</h2>
            </header>

            {/* AFFICHAGE DU CONTENU AVEC LES STYLES */}
            <div className="entry-content">
              {parsedBody.map((block, index) => renderBlock(block, index))}
            </div>



           

            {/* META-INFOS EN BAS */}
            <div className="entry-meta entry-meta-bottom">
              <div className="date-author">
                <span className="entry-date">
                  <i className="far fa-clock"></i> {new Date(currentArticle.published_date).toLocaleDateString()}
                </span>
                <span className="author vcard">
                  <i className="fas fa-user"></i> {currentArticle.author}
                </span>
              </div>

              <div className="entry-share btn-group share-buttons">
                  <a
                    href="https://www.linkedin.com/in/nadia-a-581a2048/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                <a
                    href="https://github.com/NadNadou"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-github"></i>
                  </a>
              </div>

              
            </div>

            <div className="post-tags">
              <div className="tags">
              {currentArticle.tags.map((item, index) => (
                <a href="#" key={index} rel="tag">
                  #{item}
                </a>
              ))}
              </div>
            </div>

          </div>
        </article>
      </div>
    </div>
  );
}

export default BlogDetailAdmin;