import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Settings from "../../components/admin/Settings";
import {
  getAllCategories,
  postNewCategories,
  updateCategory,
  deleteCategory,
  deleteAllCategories,
} from "../../actions/dataActions";

const SettingsPage = ({
  categories,
  getAllCategories,
  postNewCategories,
  updateCategory,
  deleteCategory,
  deleteAllCategories,
}) => {
  const [name, setName] = useState("");
  const [color, setColor] = useState("#000000");
  const [message, setMessage] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    getAllCategories();
  }, [getAllCategories]);

  // ➤ 📌 Ajouter une catégorie
  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage("");

    const newCategory = { name, color };

    postNewCategories(newCategory)
      .then(() => {
        setName("");
        setColor("#000000");
        setMessage("✅ Catégorie ajoutée avec succès !");
      })
      .catch(() => setMessage("❌ Erreur lors de l'ajout."));
  };

  // ➤ 📌 Activer le mode édition
  const handleEdit = (category) => {
    setEditMode(true);
    setSelectedCategory(category);
    setName(category.name);
    setColor(category.color);
  };

  // ➤ 📌 Mettre à jour une catégorie
  const handleUpdate = (e) => {
    e.preventDefault();
    if (!selectedCategory) return;

    const updatedCategory = { name, color };

    updateCategory(selectedCategory._id, updatedCategory).then(() => {
      setEditMode(false);
      setSelectedCategory(null);
      setName("");
      setColor("#000000");
    });
  };

  // ➤ 📌 Supprimer une catégorie
  const handleDelete = (categoryId) => {
    deleteCategory(categoryId);
  };

  return (
    <Settings
      onSubmitNewCat={editMode ? handleUpdate : handleSubmit}
      name={name}
      setName={setName}
      color={color}
      setColor={setColor}
      message={message}
      categories={categories}
      deleteCategory={handleDelete}
      deleteAllCategories={deleteAllCategories}
      updateCategory={updateCategory}
      editMode={editMode}
      setEditMode={setEditMode}
      selectedCategory={selectedCategory}
      handleEdit={handleEdit}
    />
  );
};

SettingsPage.propTypes = {
  categories: PropTypes.array.isRequired,
  getAllCategories: PropTypes.func.isRequired,
  postNewCategories: PropTypes.func.isRequired,
  updateCategory: PropTypes.func.isRequired,
  deleteCategory: PropTypes.func.isRequired,
  deleteAllCategories: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  categories: state.data.categories,
});

export default connect(mapStateToProps, {
  getAllCategories,
  postNewCategories,
  updateCategory,
  deleteCategory,
  deleteAllCategories,
})(SettingsPage);
