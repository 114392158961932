import React,{useState} from "react";

const Blog = ({ articles, categories }) => {
  const [selectedCategory, setSelectedCategory] = useState(null); // 💡 nouvelle state

  if (!articles || !Array.isArray(articles[3])) {
    return <p>Aucun article disponible.</p>;
  }

  const allArticles = articles[3]
    .slice()
    .sort((a, b) => new Date(b.published_date) - new Date(a.published_date));

  const usedCategoryIds = new Set(allArticles.map(article => article.category));
  const filteredCategories = categories.filter(cat => usedCategoryIds.has(cat._id.toString()));

  // 🔍 Filtrage des articles selon la catégorie sélectionnée
  const filteredArticles = selectedCategory
    ? allArticles.filter(article => article.category === selectedCategory)
    : allArticles;

  const handleCategoryClick = (categoryId) => {
    // toggle si on clique sur la même catégorie : reset
    if (categoryId === selectedCategory) {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(categoryId);
    }
  };

  return (
    <div className="content-area">
      <section className="animated-section">
        <div className="section-content">
          <div className="page-title">
            <h2>Blog</h2>
          </div>

          {/* Barre des catégories */}
          {filteredCategories.length > 0 && (
            <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
              <div className="categories-row">
                {filteredCategories.map((category) => (
                  <div key={category._id} className="category-item">
                    <button
                      onClick={() => handleCategoryClick(category._id.toString())}
                      className={`category-button ${
                        selectedCategory === category._id.toString() ? "active" : ""
                      }`}
                      style={{ "--cat-color": category.color || "#ccc" }}
                    >
                      {category.name}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}

          <hr />

          {/* Liste des articles filtrés */}
          <div className="row">
            <div className="col-xs-12 col-sm-12">
              <div className="blog-masonry two-columns clearfix">
                {filteredArticles.map((article, index) => {
                  const category = categories.find(cat => cat._id.toString() === article.category);
                  const categoryName = category?.name || "Non classé";
                  const categoryColor = category?.color || "#ccc";

                  return (
                    <div key={article.id} className={`item post-${index + 1}`}>
                      <div className="blog-card">
                        <div className="media-block">
                          <span
                            style={{
                              backgroundColor: categoryColor,
                              color: "#fff",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              display: "inline-block",
                              fontWeight: "bold",
                              marginBottom: "10px"
                            }}
                          >
                            <a href="#" style={{ color: "inherit", textDecoration: "none" }} title={`Voir tous les articles dans ${categoryName}`}>
                              {categoryName}
                            </a>
                          </span>

                          <a href={`/blog/${article.linkFormat}`}>
                            <img
                              src={article.picture?.url || "default.jpg"}
                              className="size-blog-masonry-image-two-c"
                              alt={article.title}
                              title={article.title}
                            />
                            <div className="mask"></div>
                          </a>
                        </div>
                        <div className="post-info">
                          <div className="post-date">
                            {new Date(article.published_date).toLocaleDateString("fr-FR", {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })}
                          </div>
                          <a href={`/blog/${article.linkFormat}`}>
                            <h4 className="blog-item-title">{article.title}</h4>
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};


export default Blog;
