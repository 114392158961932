import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const DashboardPage = () => {


   return (
    <div className="content-area">
        <section class="animated-section">
            <div class="section-content">
                <div class="page-title">
                    <h2>Dashboard</h2>
                </div>
                
                <div class="row">
                    <div class="col-xs-12 col-sm-6">
                    <div class="certificate-item clearfix">
                        <div class="certi-logo">
                        <img src="https://res.cloudinary.com/dd7puawis/image/upload/v1683741561/Other/blog_page_s1oagh.jpg" alt="logo"/>
                        </div>
                        
                        <div class="certi-content">
                        <div class="certi-title">
                            <a href="/dashboard/blog">Blog</a>
                        </div>
                       
                        </div>
                    </div>
                    </div>
                    <div class="col-xs-12 col-sm-6">
                        <div class="certificate-item clearfix">
                            <div class="certi-logo">
                            <img src="https://res.cloudinary.com/dd7puawis/image/upload/v1683741561/Other/blog_page_s1oagh.jpg" alt="logo"/>
                            </div>
                            
                            <div class="certi-content">
                            <div class="certi-title">
                                <a href="/dashboard/settings">Settings</a>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

   )
};


DashboardPage.propTypes = {
   articles: PropTypes.array.isRequired,
   getPublicArticles: PropTypes.func.isRequired
  
};

const mapStateToProps = state => ({
   articles: state.article.all_public_articles
  
});


export default connect(
   mapStateToProps,
 )(DashboardPage);