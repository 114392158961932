import {
  SET_CURRENT_USER,
  TOGGLE_USER_LOADING,
  TOGGLE_USER_UPDATE,
  UPDATE_USER,
} from "../actions/types";

const isEmpty = require("is-empty");

const initialState = {
  isAuthenticated: false,
  clientObj: {},
  updateClient: {},
  client_loading: false,
  client_update: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        clientObj: action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        updateClient: action.payload,
      };
    case TOGGLE_USER_LOADING:
      return {
        ...state,
        client_loading: !state.client_loading,
      };
    case TOGGLE_USER_UPDATE:
      return {
        ...state,
        client_update: !state.client_update,
      };
    default:
      return state;
  }
}
