import React, {useEffect} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";


import Blog from "../../components/layout/Blog";

import {getPublicArticles} from "../../actions/articleActions"
import { getAllCategories } from "../../actions/dataActions";

const BlogPage = ({getPublicArticles,articles,getAllCategories,categories}) => {


   useEffect(() => {
      getPublicArticles();
   }, [getPublicArticles]);

      useEffect(()=>{
         getAllCategories()
      }, [getAllCategories])
      
   return (
    
          <Blog articles={articles} categories={categories}/>

   )
};


BlogPage.propTypes = {
   articles: PropTypes.array.isRequired,
   getPublicArticles: PropTypes.func.isRequired,
   getAllCategories:PropTypes.func.isRequired
  
};

const mapStateToProps = state => ({
   articles: state.article.all_public_articles,
   categories:state.data.categories
  
});


export default connect(
   mapStateToProps,
   {getPublicArticles,getAllCategories}
 )(BlogPage);