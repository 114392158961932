import React from "react";

const BlogDetail = ({ article, categories }) => { 
  if (!Array.isArray(article) || article.length === 0) {
    return <div className="content-area single-page-area"><p>Error: No article found.</p></div>;
  }

  const currentArticle = article[0];

  let parsedBody = [];
  let parsedEntityMap = {};

  try {
    const parsedContent = JSON.parse(currentArticle.body);
    parsedBody = parsedContent.blocks || [];
    parsedEntityMap = parsedContent.entityMap || {};
  } catch (error) {
    console.error("Error parsing body content:", error);
  }

  // 🔹 Récupère la catégorie et sa couleur
  const validCategories = Array.isArray(categories) ? categories : [];
  const category = validCategories.find(cat => cat._id?.toString() === currentArticle.category) || {};
  const categoryName = category.name || "Non classé";
  const categoryColor = category.color || "#ccc"; // Couleur par défaut

  console.log({validCategories})

  const renderBlock = (block, index) => {
    switch (block.type) {
      case "header-one":
        return <h1 key={index} style={{ textAlign: block.data["text-align"] || "left" }}>{block.text}</h1>;
      case "header-two":
        return <h2 key={index} style={{ textAlign: block.data["text-align"] || "left" }}>{block.text}</h2>;
      case "blockquote":
        return (
          <blockquote key={index} className="quote">
            {block.text}
            <footer className="quote-author">
              <span>{currentArticle.author || "Unknown Author"}</span>
            </footer>
          </blockquote>
        );
      case "atomic": // 📌 GESTION DES IMAGES
        if (block.entityRanges.length > 0) {
          const entityKey = block.entityRanges[0]?.key;
          const entity = parsedEntityMap?.[entityKey]; // Vérification avant d’accéder aux données
          if (entity && entity.type === "IMAGE" && entity.data?.src) {
            return (
              <div key={index} style={{ textAlign: "center", margin: "20px 0" }}>
                <img src={entity.data.src} alt="Inserted" style={{ maxWidth: "100%", borderRadius: "8px" }} />
              </div>
            );
          }
        }
        return null;
      default:
        return <p key={index} style={{ textAlign: block.data["text-align"] || "left" }}>{block.text}</p>;
    }
  };

  return (
    <div className="content-area single-page-area">
      <div className="single-page-content">
        <article className="post">
          {/* 🔹 Affichage du nom de la catégorie avec son background */}
          <div style={{ marginBottom: "15px" }}>
            <span
              style={{
                backgroundColor: categoryColor,
                color: "black",
                padding: "6px 12px",
                borderRadius: "5px",
                display: "inline-block",
                fontWeight: "bold"
              }}
            >
              <a href="#" style={{ color: "inherit", textDecoration: "none" }}>
                {categoryName}
              </a>
            </span>
          </div>

          {/* IMAGE PRINCIPALE */}
          <div className="post-thumbnail">
            <img src={currentArticle.picture?.url || "/default-image.jpg"} alt={currentArticle.title} />
          </div>

          {/* CONTENU */}
          <div className="post-content">
            <header className="entry-header">
              <h2 className="entry-title">{currentArticle.title}</h2>
            </header>

            {/* AFFICHAGE DU CONTENU */}
            <div className="entry-content">
              {parsedBody.map((block, index) => renderBlock(block, index))}
            </div>

            {/* META-INFOS */}
            <div className="entry-meta entry-meta-bottom">
              <div className="date-author">
                <span className="entry-date">
                  <i className="far fa-clock"></i> {new Date(currentArticle.published_date).toLocaleDateString()}
                </span>
                <span className="author vcard">
                  <i className="fas fa-user"></i> {currentArticle.author}
                </span>
              </div>

              <div className="entry-share btn-group share-buttons">
                  <a
                    href="https://www.linkedin.com/in/nadia-a-581a2048/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                <a
                    href="https://github.com/NadNadou"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-github"></i>
                  </a>
              </div>
            </div>

            {/* TAGS */}
            <div className="post-tags">
              <div className="tags">
                {currentArticle.tags.map((item, index) => (
                  <a href="#" key={index} rel="tag">
                    #{item}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  );
}

export default BlogDetail;
